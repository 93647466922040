export const getChildIndex = ($element: HTMLElement): number => {
  const $parentElement = $element.parentElement;

  if (!$parentElement) {
    return -1;
  }

  return Array.from($parentElement.children).findIndex(
    $childNode => $childNode === $element,
  );
};

export const findParentWithAttribute = (
  $element: HTMLElement | null,
  attributeName: string,
): HTMLElement | null => {
  if ($element && $element.hasAttribute(attributeName)) {
    return $element;
  }

  // If not, continue to the parent element
  if ($element?.parentElement) {
    return findParentWithAttribute($element.parentElement, attributeName);
  }

  // If there are no more parent elements and the attribute is not found, return null
  return null;
};

export const isVisible = (el: HTMLElement | null) => {
  if (!el) {
    return;
  }
  return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
};
